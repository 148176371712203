<template>
  <div class="background">


  <el-dialog
  title="提示"
  :visible.sync="dialognew1"
  width="50%"
  center>
  1D币可兑换10分发卷（下载1次1G以下扣1分发卷）
  <el-input v-model="inputCount" @input="handleInputChange" placeholder="请输入D币个数（1D币可兑换10分发卷）"></el-input>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialognew1 = false">取 消</el-button>
    <el-button type="primary" @click="addCoin">确 定</el-button>
  </span>
  </el-dialog>

    <div class="toptitle">
      <div class="left">
        <div>分发应用</div>
      </div>


    <div class="center">
      <div>分发卷：{{publicCoin}}个</div>
    </div>
    

      <div class="right">
        <el-button type="warning" @click="jumpPublish" style="margin-top: 15px;">分发列表</el-button>
        <el-button type="success" @click="showAddCoin" style="margin-top: 15px;">兑换分发卷</el-button>
      </div>
    </div>
  
    <el-upload
      class="upload-demo"
      ref="uploadIpa"
      action="customize"
      :drag="true"
      :multiple="false"
      :limit="1"
      :show-file-list="false"
      :on-change="handleChange"
      :auto-upload="false"
      v-show="showUploadContent"
      accept=".ipa"
    >
    
      <div class="uploadContent">
        <div class="uploaddiv" v-show="showUploadContent">
          <el-button class="uploadbutton" type="primary"> 立刻上传</el-button>
          <div class="uploadContentTitle">
            点击按钮选择应用的安装包，或拖拽文件到此区域，<br />仅支持ipa包
          </div>
          <!-- <el-icon class="el-icon--upload"><upload-filled /></el-icon> -->
        </div>
      </div>

    </el-upload>
    <div class="progressdiv" v-show="!showUploadContent">
      <div class="uploadContentTitle">应用正在上传中，请不要关闭浏览器...</div>
      <el-progress
        :text-inside="true"
        :stroke-width="50"
        :percentage="percentage"
        :striped="true"
        :striped-flow="true"
        :duration="5"
      />
    </div>

    <el-dialog title="添加备注" :visible.sync="dialogFormVisible">
      <el-input
        v-model="note"
        autocomplete="off"
        placeholder="非必填"
      ></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmAction">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import {addPublicCoin} from '@/api/member'

import {getSelf} from '@/api/member'
import termVue from "../term.vue";
import { getLingShuLianTempSecret, publishSignIpa } from "@/api/upload";
import "../../utils/aws-sdk";
import { Loading, Message } from "element-ui";
const IpaParser = require("app-info-parser/src/ipa");

export default {
  name: "AppPublish",
  components: {},
  data() {
    return {
      showUploadContent: true,
      percentage: 0,
      dialogFormVisible: false,
      note: "",
      file: null,
      userData:null,
      dialognew1:false,
      inputCount:1,
      publicCoin:0
    };
  },

  mounted() {
    this.getMemberSelf();

  },

  methods: {

    async getMemberSelf() {
      var data = await getSelf()
      this.userData = data;
       this.publicCoin =  this.userData.dispense_info.count;
    },

    handleChange(file) {
      this.file = file;
      this.dialogFormVisible = true;
    },

    confirmAction() {
      this.beforeAvatarUpload(this.file);
      this.dialogFormVisible = false;
    },

    beforeAvatarUpload(file) {
      // 开始解析
      const parser = new IpaParser(file.raw);
      let that = this;
      parser
        .parse()
        .then((result) => {
          // 原始名字
          var originFileName = file.name;

          // 相关上传参数
          var name = originFileName.split(".ipa")[0];
          var version =
            result["versionName"] || result["CFBundleShortVersionString"];
          var bundle_id = result["CFBundleIdentifier"];
          //   var icon = result.icon;
          var size = file.size;

          // if (bundle_id == null){
          //   Message({
          //     message: "ipa error 请联系客服排查问题",
          //     type: "error",
          //     duration: 1.5 * 1000,
          //     offset: document.body.clientHeight * 0.5 - 100,
          //   });
          //   return;
          // }

          var params = {};
          params.ipa_name = name;
          params.ipa_bundle_id = bundle_id;
          params.ipa_version = version;
          params.ipa_size = parseInt(size / (1024 * 1024));
          params.note = this.note;
          console.log("params ----> ", params);
          that.uploadFile(file, params);
        })
        .catch((err) => {
          console.log("err ----> ", err);
          that.clearData();
        });
    },

    jumpPublish(){
      this.$router.push({
              path: `/app/publishlist`,
          })
    },

    showAddCoin(){
      this.dialognew1 = true;
    },

   async addCoin(){
      this.dialognew1 = false;
      var data = await addPublicCoin(this.inputCount);
      this.getMemberSelf();
    },

    handleInputChange(value) {
      const regex = /^[0-9]*$/;
      if (!regex.test(value)) {
        this.inputCount = value.replace(/[^0-9]/g, '');
      }
    },

    async uploadFile(file, publish_params) {
      var res = await getLingShuLianTempSecret();
      this.showUploadContent = false;
      AWS.config.update({
        credentials: {
          accessKeyId: res["secret_id"],
          secretAccessKey: res["secret_key"],
        },
        endpoint: "http://s3-us-east-1.ossfiles.com",
        region: "us-east-1",
        apiVersions: {
          s3: "2006-03-01",
        },
        signatureVersion: "v4",
      });
      var s3 = new AWS.S3();
      // 多线程上传文件
      var params = {
        Bucket: res["bucket_name"],
        Key: res["key"],
        Body: file.raw,
      };
      var options = {
        partSize: 6 * 1024 * 1024,
        queueSize: 5,
      };
      publish_params["ipa_file_token"] = res["key"];
      let that = this;
      s3.upload(params, options, function (err, data) {
        if (err) {
          this.loading.close();
          Message({
            message: err,
            type: "error",
            duration: 1.5 * 1000,
            offset: document.body.clientHeight * 0.5 - 100,
          });
          that.showUploadContent = true;
        }
        that.publish(publish_params);
      }).on("httpUploadProgress", function (data) {
        that.percentage = parseInt((data["loaded"] * 100) / data["total"]);
      });
    },

    async publish(data) {
      var res = await publishSignIpa(data);
      if (res.error != null) {
      } else {
        this.$router.push({
          path: `/app/publishlist`,
        });
      }
      this.clearData();
    },

    clearData() {
      this.file = null;
      this.note = "";
      this.showUploadContent = true;
      this.percentage = 0;
      this.$refs["uploadIpa"].clearFiles();
    },
  },
};
</script>

<style scoped lang="scss">
.toptitle {
  display: flex;
  height: 100px;
  width: 100%;
  align-items: center;
  background-color: #6194f2;
}
.left {
  color: white;
  text-align: start;
  margin-left: 5%;
  width: 50%;
  // background-color: red;
  font-size: 20px;
  font-weight: 800;
}

.right {
  color: white;
  text-align: end;
  width: 50%;
  margin-right: 5%;
  font-size: 17px;
  font-weight: 600;
}

.center {
  color: white;
  text-align: center;
  width: 100%;
  font-size: 17px;
  font-weight: 600;
  margin: 0 auto; /* 添加这一行 */
  }

.background {
  background-color: white;
  position: relative;
  align-items: center;
  justify-items: start;
  text-align: start;
  display: flex;
}

.uploadContentTitle {
  justify-items: center;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.uploadContent {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
}

.uploaddiv {
  width: 100%;
}

.upload-demo {
  width: 100%;
  height: 100%;
}

.uploadbutton {
  width: 130px;
  height: 40px;
  border-radius: 5px;
  background-color: #6194f2;
  font-size: 17px;
}

.progressdiv {
  display: block;
  padding-top: 20%;
  height: 80%;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  align-items: center;
  justify-items: center;
}

::v-deep .el-progress-bar__innerText {
  font-size: 20px;
  font-weight: 800;
}

::v-deep .el-upload {
  width: 90%;
  height: 80%;
  padding: 50px 5% 50px 5%;
  //   line-height: 100px;
}

::v-deep .el-upload .el-upload-dragger {
  width: 100%;
  height: 100%;
}
</style>
