
export function splitIpaName(text) {
    var sp1 = text.split("#")[0];
    return sp1;
}

 export function splitVersion(text) {
    return text.split("#")[1]; 
 }

 export function splitBundleId(text) {
    var sp1 = text.split("#")[2];
    sp1 = sp1.substring(0, sp1.length - 4);
    return sp1;
}

