<!--
 * @Author: your name
 * @Date: 2022-01-09 15:45:50
 * @LastEditTime: 2022-09-05 14:45:29
 * @LastEditors: zhangdasen zhangdasen@conew.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /smash_web/src/views/installIpa.vue
-->
<template>

<div class="background2">
  <div class="flex algin">
    <div id="app-title" class="app-title"> 锤子助手 APP
      <el-button id="app_back_btn" @click="onBack" class="app_back_btn" >返回兑换</el-button> 
    </div>
  </div>

<div class="center-container-cer2">
<el-form>
  <div class="container_app">

    <div>
      DumpApp 网站正式发布 <label style="color:red">手机版APP</label>，软件完美集成了 DumpApp 网站的核心功能可直接脱离电脑，在手机直接下载，签名，安装 APP<br><br>
      
    </div>
        <el-button type="danger" size="medium" @click="updatedoc()">- 更新APP教程-</el-button> <br><br>
        <el-button type="primary" size="medium" @click="installdoc()">安装教程</el-button>
          <el-button type="primary" size="medium" @click="usedoc()">使用教程</el-button> <br><br>
        下面提供了三种安装方式，推荐兑换码安装。
        <el-row>
        <el-button type="danger" size="medium" @click="codedoc()">兑换码安装(推荐)</el-button>
        <el-button type="warning" size="medium" @click="signdoc()">自签名安装</el-button>
        <el-button type="success" size="medium" @click="downloaddoc()">下载 IPA</el-button>
        </el-row>

          <span>.</span>
    <el-divider></el-divider>

<div class="app_version">
    <span><label style="color:red">{{currentVersion}}</label>
      <br> * 
      {{describe}}
</span>
<br>
<br>
    <el-button type="warning" size="medium" @click="updateContentDoc()">更新日志</el-button>

</div>
  </div>
</el-form>
</div>
</div>
</template>

<style>
.app_version{
  text-align: left;
}
  .container_app{
    /* flex-direction: row; */
    padding: 5px;
  }
  .center-container-cer2{
    background-color: white;
    width:70%;
    min-width: 340px;
    height:560px;
  } 

 .background2 {
    width: 100%;
    height: 1000px;
    background-color: #348097;
    display:flex;
flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

 .background6 {
    width: 100%;
    height: 800px;
    background-color: rgb(70, 70, 71);
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

</style>

<script>

import {getAppVersion} from '@/api/member'

export default {
  data() {
    return {
      versionData:[],
      downloadUrl :"",
      currentVersion:"",
      describe:""
    }
  },

  methods: {

      async requestVersion() {
          var data =  await getAppVersion();
          if (data !=null){
            this.versionData = data;
            var time = this.versionData.data[0].created_at;
            this.downloadUrl =  this.versionData.data[0].update_ipa_url;
            this.currentVersion = "V " + this.versionData.data[0].version + " - " + this.formatDate(time);
            this.describe = this.versionData.data[0].describe;
          }

      },


      formatDate(nS) {     
  return new Date(parseInt(nS) * 1000).toLocaleDateString();     
},
      downloaddoc(){
        window.open(this.downloadUrl);
      },
      signdoc(){
        window.open("https://sign.dumpapp.com");
      },
      codedoc(){
        window.open("https://install.dumpapp.com/home");
      },
      usedoc(){
        window.open("https://dumpappdoc.feishu.cn/docx/doxcnUUe4ti2rYSNz535iB9NFsh");
      },
      installdoc(){
        window.open("https://dumpappdoc.feishu.cn/docx/doxcn53S4Kh8amfl07mHAhBgiTh");
      },
      updatedoc(){
        window.open("https://dumpappdoc.feishu.cn/docx/doxcnoXzH0WhQ4Avd2Yn6T7ogPg");
      },
     updateContentDoc(){
        window.open("https://dumpappdoc.feishu.cn/docx/doxcnBzaRqIwKtAOZrvaVFjPYkf");
      },
    async onBack() {
       window.location.href = "https://install.dumpapp.com"
    },
 
  },

  mounted() {
    this.requestVersion();
  },

}
</script>
