<template>

<div class="background">
  <div class="flex algin">
    <div class="app-title">Dump APP 购买D币
    </div>
       <el-button id="app_back_btn" @click="onBack" class="app_back_btn" >返回首页</el-button>
  </div>

  
  <el-dialog
  title="兑换D币（兑换码）"
  :visible.sync="inputCodeDialog"
  width=300px
  center>
  <van-field
  v-model="clipboardText"
  rows="5"
  autosize
  size="large"
  label=""
  type="textarea"
  placeholder="请输入D币兑换码，每行一个（一键复制后粘贴到这里，会自动识别）"
/>

<span slot="footer" class="dialog-footer">
  <el-button @click="inputCodeDialog = false">取 消</el-button>
  <el-button type="primary" @click="createUserCode()">确 定</el-button>
  <el-button type="success" @click="copyToField()">粘贴</el-button>
</span>
</el-dialog>

  
<div  class="center-container—pay">
  
  <label style="margin-bottom:100px; padding-bottom: 10px;margin-top:10px">{{description}}<br>
  </label>

    <van-radio-group v-model="checked"  >
  <van-cell-group inset>

<el-button size="medium" type="primary"  style="margin-top:10px" @click="successDelegate()">更多优惠</el-button>
    <van-cell title="" clickable @click="checked = '1'">
      <template #right-icon>
     
  <el-input style="width: 200px; margin-right: 15px;" v-model="num" placeholder="手动输入D币数量"></el-input>
      <van-radio name="1" />
      
      </template>
    </van-cell>
    <van-cell title="35 D币" clickable @click="checked = '35'">
      <template #right-icon>
        <van-radio name="35" />
      </template>
    </van-cell>
    <van-cell title="500 D币" clickable @click="checked = '500'">
      <template #right-icon>
        <van-radio name="500" />
      </template>
    </van-cell>
    <van-cell title="1500 D币" clickable @click="checked = '1500'">
      <template #right-icon>
        <van-radio name="1500" />
      </template>
    </van-cell>
    <van-cell title="3000 D币" clickable @click="checked = '3000'">
      <template #right-icon>
        <van-radio name="3000" />
      </template>
    </van-cell>
    <van-cell title="5000 D币" clickable @click="checked = '5000'">
      <template #right-icon>
        <van-radio name="5000" />
      </template>
    </van-cell>

  
    <div class="bottom_pay">
      
<el-button size="medium" type="success"  class="paybtn"  @click="successvip()">购买D币</el-button>
<el-button size="medium" type="primary"  @click="buyThird()">购买充值卡(D币)</el-button>
<el-button size="medium" type="warning"  @click="codeSuccess()">充值卡兑换D币</el-button>
</div>
  </van-cell-group>
</van-radio-group>

</div>

</div>
</template>

<style>

.vip_info{
  position:relative;
  top:5%;
  left:5%
}

.payValue{
  left: 8px;
  font-size: 18px;
  color: red;
  position: relative;
}

.paybtn{
  width:120px;
  height: 40px;
  margin-left: 10px;
}

.paybtn2{
  width:140px;
  height: 40px;
  margin-left: 10px;
}

.numInput{
  width: 180px;
  height: 60px;
  position: relative;
}

.numInputText{
  top: 55px;
  width: 180px;
  height: 60px;
  font-size: 18px;
  color: red;
  position: relative;
}

  .grid-content {
    top: 30px;
    border-radius: 4px;
    min-height: 106px;
    width: 310px;
    left: 40px;
    position:relative;
  }
.payImage{
  top: 30px;
  left: 40px;
  height: 90px;
  position: relative;
}
.payType{
  top: 80px;
  left: -80px;
  position:relative;
}
.payValue{
  top: 15px;
  position: relative;
}

.payNew{
  height: 120px;
  position: relative;
}

.el-radio{
  top: 20px;
}
  .el-row {
    margin-bottom: 20px;
    top: 40px;
  }
  .el-col {
    border-radius: 4px;
    margin-left: 60px;
  }
  .bg-purple {
    height: 100px;
    background: #dceff7;
    min-width: 210px;
    margin-left: 80px;
    margin-right: 80px;
  }
  .row-bg {
    padding: 5px;
    background-color: #f9fafc;
  }
  .center-container—pay{
    background-color: white;
    width:65%;
    height:370px;
    min-width: 300px;
    padding: 20px;
  }

  .container{
    /* display: flex; */
    flex-direction: row;
    padding: 5px;
  }
  .bottom_pay{
    margin-top: 20px;
  }
  .login-button{
    height: 75px;
    line-height: 75px;
  }

  .table_content{
    top: 20px;
    position: relative;
  }

</style>

<script>

import {addCoinWithIds} from '@/api/member'
import {getPriceLink} from '@/api/member'
import {getSelf} from '@/api/member'
export default {
  data() {
      return {
        description:"",
        showPrice:"1",
        price:"1",
        num:9,
        extentStr:"",
        extentCoin:"",
          centerDialogVisible: false,
        tableData: null,
        radio: 1,
        checked:"35",
        inputCodeDialog:false,
        clipboardText:"",
        guest:false,
      
      }
    },

  methods: {
      async getMemberSelf() {
        var data = await getSelf();

        if (data.type == 'guest'){
            this.guest = true;
          }else{
            this.guest = false;
          }
          this.description = "单次支付288币，或充值预存1500币自动成为代理。代理权限：1、证书价格超低  2、超长证书售后服务 3、免费技术支持（更多福利优惠活动不定期开放）";
      },
    async onBack() {
        this.$router.push({
          path: `/`,
        })
    },
    successvip(){
      this.getVip2();      
    },

    successDelegate(){
      window.open("https://dumpappdoc.feishu.cn/docx/PHbadYcJToYvLBxfshMcs0qznqd")
    },

    clickNew(){
      this.centerDialogVisible = false;
      this.centerNewDialogVisible = true;
    },
    
  
    async createUserCode(){
        let newString = this.clipboardText.replace(/卡密：/g, '').trim();
        const arr = newString.split('\n');
        var data = await addCoinWithIds(arr);
        if (data.out_ids != null){
          this.$message({
            message: '兑换成功',
            type: 'success'
          });
          this.inputCodeDialog = false;
        }
      },

      copyToField(){
        navigator.clipboard.readText().then((res) => {

          res = res.replace(/卡密：/g, '').trim();


        this.clipboardText = res;
        }).catch((err) => {
            console.log('获取剪切板内容失败:', err);
        });
      },

      buyThird(){
        window.open("https://www.kajishou.com/links/93523790")
      },
      
      codeSuccess(){
        this.inputCodeDialog = true;
        navigator.clipboard.readText().then((res) => {
          res = res.replace(/卡密：/g, '').trim();
          if (res.includes('DP')){
            this.clipboardText = res;
          }
        }).catch((err) => {
          
        });
      },
    agreeChange(){
      alert("sd");
    },
     async getVip1() {
      // 拿到请求数据 json
      this.showPrice = 1
     },

     async getVip2() {

      var value = this.checked;

      if (this.checked == "1"){
        value = this.num;
      }

      // 拿到请求数据 json
      var data = await getPriceLink(value)
      
      // 判断是否登录成功
      if (data != null){
        if (this.guest == true){
          window.open(data.open_url);
         }else{
          window.location.href = data.open_url;
         }
      }else{
          Message({
          message: "网络错误，请重试！",
          type: 'error',
          duration: 2 * 1000
        })
      }
     }
  },

    mounted() {
      this.getVip1();
      this.getMemberSelf();
    }

  }
  

</script>
