<template>
  <div class="background">
    <div class="flex align">
      <div class="app-title">独立池账号管理</div>
      <el-button id="app_back_btn" @click="onBack" class="app_back_btn">返回购买</el-button>
    </div>

    <el-dialog title="添加账号（扣除100币）" :visible.sync="show" width="90%" center>
      <el-input v-model="issuerid" placeholder="Issuer ID (例如: ****_****_****_****_****)"></el-input>
      <br><br>
      <el-input v-model="keyid" placeholder="密钥 ID (例如: ***********)"></el-input>
      <br><br>
      <el-input v-model="remark" placeholder="备注"></el-input>
      <br><br>
      <el-upload
        class="upload-demo"
        :on-change="handleUploadSuccess"
        action="uploadp8"
        :auto-upload="false"
        :drag="false"
        :multiple="false"
        :limit="1"
        :show-file-list="false"
        accept=".p8"
        multiple
        :file-list="fileList"
      >
        <el-button size="small" type="primary" v-if="isUploaded">点击上传P8文件</el-button>
        <el-button size="small" type="danger" v-else>P8文件已上传</el-button>
      </el-upload>

      <span slot="footer" class="dialog-footer">
        <el-button @click="show = false">取 消</el-button>
        <el-button type="primary" @click="addAccount">添 加</el-button>
      </span>
    </el-dialog>

    <div class="center-container-book">
      <el-form>
        <div class="container">
          <el-button type="primary" icon="el-icon-circle-plus" class="add-account-btn" @click="showPopup">添加独立池账号</el-button>
          <a href="https://dumpappdoc.feishu.cn/docx/RlaBdbohGo6SjbxqCbRcjzshnJb" target="_blank" class="p8-file-link">如何获取P8文件？</a>

          <el-table :data="tableData" style="width: 100%">
            <el-table-column prop="user_name" label="账号">
              <template slot-scope="scope">
                <div v-html="scope.row.all_name" class="account-name"></div>
                <el-switch
                  v-model="scope.row.switchValue"
                  active-color="#13ce66"
                  inactive-color="#DCDCDC"
                  @change="handleSwitchChange(scope.row)"
                ></el-switch>
                <div v-html="scope.row.user_name" class="account-info"></div>
                <div v-html="scope.row.device_count" class="device-count"></div>
                <div v-html="scope.row.add_time" class="account-info"></div>
              </template>
              <template slot="header" slot-scope="scope">
                <span class="column-header">{{ scope.column.label }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="note" label="备注">
              <template slot="header" slot-scope="scope">
                <span class="column-header">{{ scope.column.label }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="url" label="操作">
              <template slot="header" slot-scope="scope">
                <span class="column-header">{{ scope.column.label }}</span>
              </template>
              <template slot-scope="scope">
                <el-button @click="downloadP12(scope.row.id)" type="danger" plain>下载P12</el-button>
                <el-button @click="syncUdid(scope.row.id)" type="primary" plain>同步UDID</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form>
    </div>
  </div>
</template>


<style>
.flex {
  display: flex;
  align-items: center;
}

.align {
  align-items: flex-start;
}

.app-title {
  font-size: 24px;
  font-weight: bold;
  margin-right: 20px;
}

.app_back_btn {
  margin-left: auto;
}

.center-container-book {
  background-color: white;
  width: 70%;
  min-width: 330px;
  height: 600px;
  padding: 20px;
  margin-top: 20px;
}

.add-account-btn {
  width: 160px;
  font-size: 14px;
  margin-bottom: 15px;
  margin-top: 5px;
}

.p8-file-link {
  color: red;
  margin-left: 10px;
}

.account-name {
  color: red;
  font-size: 15px;
  font-weight: 600;
}

.account-info {
  color: black;
}

.device-count {
  color: red;
  font-size: 13px;
  font-weight: 600;
}

.column-header {
  font-size: 18px;
  color: black;
}
</style>

<script>

import { parse } from 'pem-jwk';
import { saveAs } from 'file-saver';
import {addAppleID, getAppAccount,setAppleAccountStatus,downloadAppleP12,syncAccountUdid,deleteAccountUdid} from '@/api/member'
import {Message} from 'element-ui'
export default {
  
  data() {
    return {
          tableData: [],
          show:false,
          value:true,
          issuerid: '',
          keyid: '',
          remark:"",
          fileList:[],
          privateKey:"",
          isUploaded: true 
        }
  },
  methods: {
    beforeUpload(file) {
      const extension = file.name.split('.').pop().toLowerCase();
      if (extension !== 'p8') {
        this.$message.error('只能上传 .p8 文件');
        return false; // 阻止文件上传
      }
      return true; // 允许文件上传
    },

    handleUploadSuccess(file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const fileData = event.target.result;
        this.privateKey = btoa(fileData);

        if (fileData.length){
          this.isUploaded = false;
        }else{
          this.isUploaded = true;
        }
      };
      reader.readAsText(file.raw);
    },

   async handleSwitchChange(rowData){

      var switchStr = rowData.switchValue == true ? "on" : "off";

      var response = await setAppleAccountStatus(rowData.id, rowData.note,switchStr);
      this.requestData();
    },
    onSubmit(values) {
      console.log('submit', values);
    },
    async addAccount() {
      if (!this.issuerid.length){
        Message({
            message: "请输入 Issuer ID",
            type: 'warning',
            duration: 1500
          })
        return;
      }

      if (!this.keyid.length){
        Message({
            message: "请输入 密钥ID",
            type: 'warning',
            duration: 1500
          })
        return;
      }

      if (!this.privateKey.length){
        Message({
            message: "请选择正确的p8文件",
            type: 'warning',
            duration: 1500
          })
        return;
      }

      this.show = false;

      var response = await addAppleID(this.issuerid,this.keyid,this.privateKey,this.remark);
      if (response != null){
          Message({
            message: "添加成功！",
            type: 'success',
            duration: 2 * 1000
          })
          this.requestData();
      }else{
          Message({
          message: "添加失败，请重试！",
          type: 'error',
          duration: 2 * 1000
        })
      }
    },

   async requestData(){
      var response = await getAppAccount();
      var accounts = response.data;
      var newData = new Array();

      for (var i=0; i<accounts.length; i++){
        var account = accounts[i];
        var dev_count = "剩余 iPhone:" + account.device_count.iphone + " iPad:" + account.device_count.ipad 
        if (account.open_mac == true){
          dev_count = "剩余 iPhone:" + account.device_count.iphone + " iPad:" + account.device_count.ipad  + " Mac:" + account.device_count.mac_os
        }
        var newDic = {};
        newDic["user_name"] = account.apple_user.user_name;
        newDic["device_count"] = dev_count;
        newDic["add_time"] = this.formatDate(account.created_at);
        newDic["note"] = account.note;
        newDic["id"] = account.id;
        newDic["all_name"] = account.apple_user.first_name + " " + account.apple_user.last_name;
        if (account.status == 'off' || account.status == 'disabled'){
          newDic["switchValue"] = false;
        }else{
          newDic["switchValue"] = true;
        }
        newData.push(newDic);
      }

      this.tableData = newData;

    },

    formatDate(nS) {     
      return new Date(parseInt(nS) * 1000).toLocaleString().replace(/:\d{1,2}$/,' ');     
    },
    showPopup(){
      this.privateKey = "";
      this.isUploaded = true;
      this.show = true;
    },

    async onBack() {
        this.$router.push({
          path: `/cerBuy`,
        })
    },

    formatText(text) {
      return text.replace(/<br>/g, '<br/>');
    },

    async deleteAccount(acId){
      await deleteAccountUdid(acId);
      this.requestData();
    },

   async downloadP12(acId){
      var res = await downloadAppleP12(acId);
      this.downloadP12File(res.p12);
   },

   downloadP12File(base64Data) {
      const filename = 'developer.p12'; // 替换为你想要保存的文件名
      
      // 解码 Base64
      const decodedData = atob(base64Data);
      
      // 将解码后的数据转换为 Uint8Array
      const uint8Array = new Uint8Array(decodedData.length);
      for (let i = 0; i < decodedData.length; i++) {
        uint8Array[i] = decodedData.charCodeAt(i);
      }
      
      // 转换为 ArrayBuffer
      const arrayBuffer = uint8Array.buffer;
      
      // 创建 Blob 对象
      const blob = new Blob([arrayBuffer], { type: 'application/x-pkcs12' });
      
      // 下载文件
      saveAs(blob, filename);
    },

   async syncUdid(acId){
    var response = await syncAccountUdid(acId);
    this.requestData();

    // await this.deleteAccount(acId);
   }

  },

  mounted() {
    this.requestData();
  },
  watch: {
  },
  name: 'Login'
}
</script>
