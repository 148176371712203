<template>
  <div class="body">

    <el-dialog
    title="提示"
    :visible.sync="dialognew1"
    width="50%"
    center>
    1D币可兑换10分发卷（下载1次1G以下扣1分发卷）
    <el-input v-model="inputCount" @input="handleInputChange" placeholder="请输入D币个数（1D币可兑换10分发卷）"></el-input>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialognew1 = false">取 消</el-button>
      <el-button type="primary" @click="addCoin">确 定</el-button>
    </span>
    </el-dialog>


    <el-dialog
    title="请输入分发总数"
    :visible.sync="dialognew2"
    width="50%"
    center>
    1. 设置分发总数能够限制分发出去的包下载总次数。<br>
    2.分发次数设置为0或者为空，则是不进行限制。<br>
    3.次数限制一般可能会存在误差多扣1-2次情况，如果出现可反馈客服补分发数。
    <el-input v-model="publishCount" @input="handlePublishCountInputChange" placeholder=""></el-input>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialognew2 = false">取 消</el-button>
      <el-button type="primary" @click="addDownloadCount">确 定</el-button>
    </span>
    </el-dialog>


    <div class="toptitle">
      <div class="left">
        <div>分发列表</div>
      </div>
      
      
    <div class="center">
      <div style="color:white;width:150px;font-size: 17px;font-weight: 600;">分发卷：{{publicCoin}}个</div>
    </div>
    
      <div class="right">
        <el-button type="warning" @click="jumpUpload" style="margin-top: 15px;">上传分发</el-button>
        <el-button type="success" @click="showAddCoin" style="margin-top: 15px;">兑换分发卷</el-button>
      </div>
    </div>
    <div class="table">
      <el-table :data="tableData" height="100%" :row-class-name="rowClassName">
        <el-table-column
          prop="ipa_name"
          label="App"
          width="100px"
          align="center"
        />
        <el-table-column prop="ipa_bundle_id" label="BundleId" align="center" />
        <el-table-column prop="certificate_name" label="证书" align="center" />
        <el-table-column prop="note" label="备注" align="center" />
        <el-table-column prop="createTime" label="时间" align="center" />
        <el-table-column prop="downloadCount" label="已下载/总次数" align="center" />
        <el-table-column prop="status" label="状态" align="center">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.dispense.is_valid === false ? 'danger' : 'success'"
              disable-transitions>{{scope.row.dispense.is_valid === false ? "已到期":"正常"}}</el-tag>
           </template>
        </el-table-column>
        <el-table-column
          prop="created_at"
          label="操作"
          align="center"
          width="260px"
        >
          <!-- <el-button @click="invoicesLink(scope.row.id, scope.row.name)" type="danger" icon="el-icon-delete" circle></el-button> -->
          <template slot-scope="scope">
            <el-button type="success" @click="showSetDownloadCount(scope.row)">
              设置分发总数
            </el-button>
            <el-button type="danger" @click="deleteAction(scope.row)">
              删除
            </el-button>
            <el-button type="primary" @click="shareAction(scope.row)">
              分享
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pageDiv">
      <el-pagination
        small
        background
        layout="prev, pager, next"
        :total="total"
        class="mt-4"
        :hide-on-single-page="true"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>

import {getSelf} from '@/api/member'
import {addPublicCoin,setPublishCount} from '@/api/member'
import { getPublishSignIpaList, deletePublishSignIpa } from "@/api/upload";
import { Loading, Message } from "element-ui";
export default {
  name: "Publishlist",
  data() {
    return {
      tableData: [],
      loading: null,
      total: 0,
      currentPage: 1,
      inputCount:1,
      publishCount:1,
      publishIpaMode:{},
      publicCoin:0,
      dialognew1:false,
      dialognew2:false
    };
  },
  methods: {
    async requestData() {
      var res = await getPublishSignIpaList((this.currentPage - 1) * 10);
      this.tableData = res.data;
      this.tableData.map((item) => {
        item.createTime = this.timestampToTime(item.created_at);
        if (item.dispense.count > 0) {
          item.downloadCount = item.dispense.used_count + "/" + item.dispense.count
        }else {
          item.downloadCount = item.dispense.used_count
        }
      });
      this.total = res.paging.totals;

      console.log(res);
    },

    timestampToTime(timestamp) {
      // 时间戳为10位需*1000，时间戳为13位不需乘1000
      var date = new Date(timestamp * 1000);
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h = date.getHours() + ":";
      var m = date.getMinutes() + ":";
      var s = date.getSeconds();
      return Y + M + D + h + m + s;
    },

    rowClassName({ row, rowIndex }) {
      return "custom";
    },


    showAddCoin(){
      this.dialognew1 = true;
    },

   async addCoin(){
      this.dialognew1 = false;
      var data = await addPublicCoin(this.inputCount);
      this.getMemberSelf();
    },

    showSetDownloadCount(value){
      this.dialognew2 = true;
      this.publishIpaMode = value
    },
    ///设置分发总数
   async addDownloadCount(){
      this.dialognew2 = false;
      var data = await setPublishCount(this.publishIpaMode.id,{note:this.publishIpaMode.note,dispense_count:parseInt(this.publishCount)});
      this.tableData.map((item) => {
        if (item.id === data.id) {
          item.dispense = data.dispense
          if (item.dispense.count > 0) {
            item.downloadCount = item.dispense.used_count + "/" + item.dispense.count
          }else {
            item.downloadCount = item.dispense.used_count
          }
        }
        
      });
    },



    handleCurrentChange(page) {
      this.currentPage = page;
      this.requestData();
    },

    jumpUpload(){
      this.$router.push({
              path: `/app/publish`,
          })
    },

    handleInputChange(value) {
      const regex = /^[0-9]*$/;
      if (!regex.test(value)) {
        this.inputCount = value.replace(/[^0-9]/g, '');
      }
    },

    handlePublishCountInputChange(value) {
      const regex = /^[0-9]*$/;
      if (!regex.test(value)) {
        this.publishCount = value.replace(/[^0-9]/g, '');
      }
    },

    async deleteAction(row) {
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var res = await deletePublishSignIpa(row.id);
      //   console.log("delete", res);
      if (res == "ok") {
        // let index = this.tableData.findIndex((item) => item.id === row.id);
        // if (index !== -1) {
        //   this.tableData.splice(index, 1);
        // }
        if (this.tableData.length == 1 && this.currentPage > 1) {
          this.currentPage -= 1;
        }
        this.requestData();
      }
      this.loading.close();
    },

    async getMemberSelf() {
      var data = await getSelf()
      this.userData = data;
       this.publicCoin =  this.userData.dispense_info.count;
    },

    shareAction(row) {
        this.$copyText(row.download_url).then(
          (res) => {
            Message({
              message: "链接已复制，请前往社交软件分享！",
              type: "success",
              duration: 1.5 * 1000,
              offset: document.body.clientHeight * 0.5 - 100,
            });
          },
          (err) => {}
        );
    },
  },
  mounted() {
    this.requestData();
    this.getMemberSelf();
  },
};
</script>

<style scoped lang="scss">
.dialog-footer {
  align-items: center;
  text-align: center;
}
.toptitle {
  display: flex;
  position: fixed;
  height: 100px;
  width: 100%;
  align-items: center;
  background-color: #6194f2;
}

.left {
  color: white;
  text-align: start;
  margin-left: 30px;
  width: 50%;
  font-size: 20px;
  font-weight: 800;
}

.right {
  text-align: end;
  width: 50%;
  margin-right: 5%;
  font-size: 17px;
  color: #666666;
}

.body {
  position: relative;
  width: 100%;
  height: 100%;
}
.table {
  position: absolute;
  top: 100px;
  bottom: 100px;
  left: 0;
  right: 0;
}
.pageDiv {
  position: absolute;
  display: inline-flex;
  height: 100px;
  bottom: 0;
  left: 0;
  right: 20px;
  align-items: center;
  justify-content: flex-end;
}
</style>