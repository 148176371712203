<template>

<div class="background">
  <div class="flex algin">
    <div class="app-title">管理中心
    </div>
       <el-button id="app_back_btn" @click="onBack" class="app_back_btn">返回首页</el-button>
  </div>

  <div class="center-container-user">
    <el-avatar class= "top_avatar" id="user_avatar" :src="circleUrl"></el-avatar>
    <el-form class="table_content" ref="form" :model="form" label-width="80px">
      <el-form-item label="邮箱地址">
         <label>{{email}}</label>
      </el-form-item>

      <el-form-item label="D币个数">
        <label>{{time}}</label>
        <el-button type="success" size="medium" @click="onClickVip()">进行充值</el-button>
        <el-button id="manager_btn" type="success" size="medium" @click="onClickManager()">管理中心</el-button>
        <el-button id="dcoinuser_btn" type="success" size="medium" @click="onClickUserCoin()">返利明细</el-button>
      </el-form-item> 
      <el-form-item label="证书中心">
        <el-button type="primary" size="medium" @click="onCerPage()">证书管理</el-button>
        <el-button type="primary" size="medium" @click="onBuyCer()">独立池管理</el-button>
      </el-form-item>
       
      <label>邀请注册 <a style="color:red">可持续获得所有被邀请人充值金额 5% D币的返利</a><br></label>
     <li></li>
      <label>开放接口 Token : <a style="color:red">{{usertoken}}</a>（对接接口使用）<br></label>
    </el-form>
    <el-button class="exit_btn" @click="onExitLogin">退出登录</el-button>
      <el-button class="exit_btn" type="warning" size="medium" @click="copyLink()">复制邀请链接</el-button>
      <el-button class="exit_btn" type="danger" size="medium" @click="copyToken()">复制Token</el-button>
  </div>
    
</div>

</template>

<style>

  .table_content{
    top: 30px;
    left: 10px;

    position: relative;
  }

  .top_avatar{
    top: 18px;
    width: 70px;
    height: 70px;
    position: relative; 
  }

  .exit_btn{
    top: 30px;
    width: 100px;
    height: 40px;
    position: relative;
  }
  .exit_btn{
    top: 30px;
    width: 120px;

    height: 40px;
    position: relative; 
  }

  .center-container-user{
    background-color: white;
    width:70%;
    min-width: 350px;
    height:420px;
  }

  .container{
    flex-direction: row;
    padding: 5px;
  }

  .login-button{
    height: 75px;
    line-height: 75px;
  }

  .table_content{
    top: 20px;
    position: relative;
  }

</style>

<script>
import {Loading, Message} from 'element-ui'
import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
import {postLogout} from '@/api/login'
import {getSelf} from '@/api/member'
  export default {
    data() {
      return {
        downUrl:"",
        email:"",
        time:"",
        circleUrl:"https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F201411%2F12%2F20141112224259_V8wWX.thumb.1000_0.gif&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1671118353&t=128c0d0675fb2274ae1401be0005a09b",
        form: {},
        vipBtn:'none',
        freeLabel:'none',
        usertoken:""
      }
    },

    methods: {
      onSubmit() {
        console.log('submit!');
      },

     onBack() {
        this.$router.push({
          path: `/`,
        })
    },

    onCerPage(){
       this.$router.push({
          path: `/cerPage`,
        }) 
    },

     formatDate(timestamp) {
      var date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
       var Y = date.getFullYear() + '.';
       var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth() + 1) : date.getMonth()+1) + '.';
       var D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + ' ';
       return Y + M + D;
    },
    onClickManager(){
        this.$router.push({
          path: `/appmanager`,
        }) 
    },

    onExitLogin() {
      // 清空 cookie
      window.sessionStorage.clear();
      postLogout();

      // 延时 500 毫秒，回到首页
      var self = this;
      setTimeout(function(){
        self.$router.push({
            path: `/`,
        });
      },1500);
    },
  onClickVip(){
      this.$router.push({
            path: `/pay`,
      })
  },

  onClickHistory(){
     this.$router.push({
            path: `/downloadhistory`,
     }) 
  },

  onClickUserCoin(){
     this.$router.push({
            path: `/coinuserpage`,
     }) 
  },

    onBuyCer(){
      this.$router.push({
            path: '/cerPrivate'
      })
    },

   async getUserInfo(){
      var userData = await getSelf()
      this.email = userData.email;
      this.usertoken = userData.token;
      this.downUrl = userData.invite_url;
      this.time = userData.pay_count + " 个        ";

      if (userData.is_admin){
        document.getElementById("manager_btn").style.display="";
      }
    
      if (userData.role == "agent"){
        this.email = this.email  + " (代理权益享受)"
      }
      
    },

    dcoinuser_btn(){

    },

  copyLink(){
   this.$copyText(this.downUrl).then(
    (res) => {
    Message({
       message:"拷贝邀请链接成功，赶紧去推广吧，永久获得所有被邀请人每单充值的 5% 返利。",
          type: 'success',
          duration: 1.5 * 1000,
          offset:document.body.clientHeight * 0.5 - 100
        })
    },
    (err) => {
    }
  );
  },

  copyToken(){
   this.$copyText(this.usertoken).then(
    (res) => {
    Message({
       message:"拷贝 TOKEN 成功",
          type: 'success',
          duration: 1.5 * 1000,
          offset:document.body.clientHeight * 0.5 - 100
        })
    },
    (err) => {
    }
  );
  },
   
  clearAllCookie() {
				var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
				if(keys) {
					for(var i = keys.length; i--;)
						document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString()
		  }
    },
	},

    mounted() {
       document.getElementById("manager_btn").style.display="none";
       
        this.getUserInfo();
    }
  }
</script>