<!--
 * @Author: your name
 * @Date: 2022-01-09 15:45:50
 * @LastEditTime: 2022-09-04 22:38:58
 * @LastEditors: zhangdasen zhangdasen@conew.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /smash_web/src/views/installIpa.vue
-->
<template>

<div class="background6">
  <div class="flex algin">
    <div id="app-title" class="app-title">仅供测试，禁止用于非法用途
    </div>
  </div>

  <van-overlay :show="show" @click="show = false">
    <div class="wrapper2" @click.stop>
      <label style="color:azure;font-size:19px;font-weight:bold"> 请点击右上角按钮，在弹出的菜单中，选择 在safari(默认浏览器)中打开! </label>
    </div>
  </van-overlay>

<div class="center-container-ipa">
<el-form>
  
  <div class="container_install">
    <template>
      <el-table
        :data="tableData"
        style="width: 100%; margin-top:20px; margin-bottom:20px">
        <el-table-column
          prop="name"
          label="名字">
        </el-table-column>
        <el-table-column
          prop="version"
          label="版本">
        </el-table-column>
        <el-table-column
        prop="link"
        label="生成二维码">
        <template slot-scope="scope">
          <el-button @click="copyLink(scope.row.fileId)" type="warning" icon="el-icon-document-copy" circle></el-button>
         </template>
        </el-table-column>
        <el-table-column
        prop="download"
        label="安装">
        <template slot-scope="scope">
          <el-button @click="instalIPA(scope.row.fileId)" type="success" icon="el-icon-download" circle></el-button>
         </template>
        </el-table-column>
        
      </el-table>
    </template>
 <div id="qrcode" class="qrcode" ref="qrcodeContainer"></div> 
  </div>
</el-form>
</div>
</div>
</template>

<style>


.center-container-ipa{
  background-color: white;
  width:90%;
  min-width: 340px;
  height:450px;
}
.wrapper2 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}


.container_install{
  flex-direction: row;
  padding: 10px;
}
  .select-cer{
    width:60%;
  }
  .showText{
    font-size: 17px;
    width: 100%;
    padding:0;
    height:40px;
  }
  
  .login-button{
    height: 75px;
    line-height: 75px;
  }
.qrcode{
  position:relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-align:center;
  width: 20;
}
</style>

<script>

import { Dialog } from 'vant';
import {deleteCoin} from '@/api/install'
import {Message} from 'element-ui'
import QRCode from 'qrcodejs2'
import {getSignedApp} from '@/api/member'
import CryptoJS from 'crypto-js';
import {Loading} from 'element-ui'
export default {
  data() {
    return {
      udid: '点击按钮，立即安装APP',
      password: '',
       dialogVisible: false,
       fits: ['fill'],
       options: [],
       value: '',
       loading :null,
       loading_edit: true,
       userData : null,
       code:"",
       ipaUrl:"",
       installURL:"",
       fileId:"",
       ids:[],
       idsData:[],
       tableData: [],
       show:false
    }
  },

  methods: {
     checkIsWeChat(){
        if (navigator.userAgent.indexOf("MicroMessenger") !== -1) {
            alert("请点击右上角按钮，在弹出的菜单中，选择 在safari(默认浏览器)中打开!");
            return true;
        }else{
            return false;
        }
    },

     creatQrCode() {
      document.getElementById("qrcode").innerHTML = "";
      new QRCode(this.$refs.qrcodeContainer, {  
          text: this.ipaUrl,  
          width: 200,  
          height: 200,
          colorDark: '#000000',  
          colorLight: '#ffffff',  
          correctLevel: QRCode.CorrectLevel.H  
      });
    },

    async requestIPA(id){
      this.ids = id.split(',');

      for (var i=0;i<this.ids.length; i++){

        var requestId = this.ids[i];
        var data = await getSignedApp(requestId,false);

        var name = data.ipa_name;
        var version = data.ipa_version;
        var fileId = data.id;

        var newDic = {}
        newDic["name"]  = name;
        newDic["version"]  = version;
        newDic["fileId"]  = fileId;
        this.tableData.push(newDic);
      }
    },

    // 扣费接口
    async deleteCoinMethod(fileId,installURL) {
      var res = await deleteCoin(fileId);
      if (res != null && res != undefined) {
        localStorage.setItem(installURL, "1");
      }
    },

    getQueryString(name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
        var r = decodeURI(window.location.search.substr(1)).match(reg);
        if (r != null)return unescape(r[2]);
        return null;
    },

    async copyLink(fileId){
      this.ipaUrl = "https://www.dumpapp.com/installipa?id=" + fileId;
      this.creatQrCode();
      this.$copyText(this.ipaUrl ).then(
      (res) => {
      Message({
        message:"链接已复制！",
            type: 'success',
            duration: 1 * 1000,
            offset:document.body.clientHeight * 0.5 - 100
          })
      },
      (err) => {
      }
    );

    },

    installApp(fileId,installURL){
      window.location.href = installURL;
      var saveState = window.localStorage.getItem(installURL);
      if (saveState != "1"){
        this.deleteCoinMethod(fileId,installURL);
        console.log("扣费成功");
      }else{
        console.log("没扣费");
      }
    },

   async instalIPA(fileId){
    var data = await getSignedApp(fileId, true);
        if (data != undefined){
            if(data.error != undefined){
              Message({
                  message: data.error.messae,
                  type: 'error',
                  duration: 2 * 1000
              })
            }else{
              var plistUrl = data.plist_url_v2;
              if (plistUrl == undefined){
                plistUrl = data.plist_url;
              }
              if (plistUrl != undefined){
                Dialog.confirm({
                  title: '提示',
                  message:
                    '是否确认进行安装？',
                }).then(() => {
                 var options = {
                    body: true,
                    lock: true,
                    text: '正在读取中......',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.6)'
                  }

                  var installURL = "itms-services:\/\/?action=download-manifest&url=" + plistUrl;
                  let loadingInstance = Loading.service(options);
                  var tThis = this;
                  setTimeout(function() {
                    loadingInstance.close();
                    tThis.installApp(fileId,installURL);
                  }, 2000);
                })
              }
             }
        }else{
             Message({
                  message: data.error.messae,
                  type: 'error',
                  duration: 2 * 1000
              }) 
        }
    }
  },

  mounted() {
    var appID = this.getQueryString("id");
    this.requestIPA(appID);

    this.show =  this.checkIsWeChat();
    
  },
}
</script>
